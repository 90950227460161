import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from 'gatsby-plugin-image';

// Components
import Layout from '../components/layout/Layout';
import TestimonialsSection from '../components/section/Testimonials';
import ContactSection from '../components/section/Contact';
//import MemberCard from '../components/card/Member';

const TeamPage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
          socialNetworks {
            facebook
            instagram
            linkedIn
          }
          author {
            name
            url
            creator
            image {
              url
            }
          }
          publisher {
            name
            url
            logo {
              url
            }
          }
        }
      }
      headerBackgroundImage: file(relativePath: { eq: "headers/equipe-pcc-batiment.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      backgroundImage: file(relativePath: { eq: "backgrounds/equipe-pcc-batiment-1.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      members: allMdx(
        filter: {
          fileAbsolutePath: { regex: "/data/members/" },
          frontmatter: { home: { eq: true }, active: { eq: true } }
        },
        sort: {fields: frontmatter___date, order: DESC}
      )
      {
        nodes {
          frontmatter {
            name
            description
            position
            website
            email
            telephone
            social_networks {
              linkedIn
              facebook
              instagram
              twitter
            }
            image {
              card {
                childImageSharp {
                  gatsbyImageData
                  fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
              credit {
                text
                link
              }
            }
            home
            active
            date(formatString: "D MMMM YYYY", locale: "fr")
          }
          id
          slug
          body
        }
        totalCount
      }
    }
  `)

  // Set ImageData.
  const headerBackgroundImage = query.headerBackgroundImage.childImageSharp.fluid
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  const backgroundImage = query.backgroundImage.childImageSharp.fluid

  return (
    <Layout 
      pageTitle={`L'équipe | ${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, aménagement, isolation intérieure et extérieure à Roanne`}
      pageDescription={`L'équipe dynamique et expérimentée de ${query.site.siteMetadata.title} à votre service depuis 30 ans. Découvrez ce que nos clients disent de ${query.site.siteMetadata.title}.`}
      pageKeywords="pcc bâtiment, équipe, témoignages, avis, roanne, coteau, loire, travaux, plâtrerie, peinture"
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="title text-white">L'équipe</h2>
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">Équipe</span>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <BackgroundImage
        id="team"
        Tag="section"
        fluid={backgroundImage}
        style={{ backgroundSize: 'auto', backgroundRepeat: 'repeat', }}
      >
        <div className="container">
          <div className="section-title">
            <div className="row mb-40">
              <div className="col-sm-6">
                <h5 className="tm-sc tm-sc-line-with-text mt-0 mb-0 text-gray line-after-title "> <span className="horizontal-text">L'équipe de <span className="text-theme-colored2">{query.site.siteMetadata.title}</span></span> <span className="horizontal-line bg-theme-colored1"></span></h5>
                <div className="tm-sc section-title section-title-style1 text-left">
                  <div className="title-wrapper">
                    <h2 className="title"> <span>À votre </span> <span className="text-theme-colored1">service</span></h2>
                    <div className="title-seperator-line"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mt-sm-15 mt-10 mb-0">
                  <p>Depuis 30 ans, {query.site.siteMetadata.title} met son savoir-faire au service des particuliers et des professionnels. Notre équipe dynamique et expérimentée mettra tout en œuvre afin de réaliser vos projets à Roanne et ses alentours.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section-content">
            <div className="row">
              <StaticImage src="../images/team/equipe-pcc-batiment.png" alt={`Équipe de ${query.site.siteMetadata.title} à Le Coteau`} />
            </div>
          </div>
        </div>
      </BackgroundImage>
      {/*
      {query.members && query.members.nodes.length ?
        <BackgroundImage
          id="testimnonials"
          Tag="section"
          fluid={backgroundImage}
          style={{ backgroundSize: 'auto', backgroundRepeat: 'repeat', }}
        >
          <div className="container">
            <div className="section-title">
              <div className="row mb-40">
                <div className="col-sm-6">
                  <h5 className="tm-sc tm-sc-line-with-text mt-0 mb-0 text-gray line-after-title "> <span className="horizontal-text">Les professionnels</span> <span className="horizontal-line bg-theme-colored1"></span></h5>
                  <div className="tm-sc section-title section-title-style1 text-left">
                    <div className="title-wrapper">
                      <h2 className="title"> <span>À votre </span> <span className="text-theme-colored1">service</span></h2>
                      <div className="title-seperator-line"></div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mt-sm-15 mt-10 mb-0">
                    <p>Depuis 30 ans, {site.siteMetadata.title} met son savoir-faire au service des particuliers et des professionnels. Notre équipe dynamique et expérimentée mettra tout en œuvre afin de réaliser vos projets à Roanne et ses alentours.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                {query.members.nodes.map(member => (
                  <MemberCard key={member.id} data={member} cardStyle="2" cardClassName="col-lg-3 col-md-4 col-sm-6 col-12 mb-30" />
                ))}
              </div>
            </div>
          </div>
        </BackgroundImage>
      : null}
      */}
      <TestimonialsSection site={query.site} sectionStyle="1" />
      <ContactSection site={query.site} sectionStyle="1" />
    </Layout>
  )
}

export default TeamPage
